import React from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import Specs from '@/components/common/Specs';
import ContactForm from '@/components/section/Contact/ContactForm';
import Map from '@/components/section/Contact/Map';
import Seo from '@/components/seo';
import { useSiteQuery } from '@/query/site';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './contacto.module.scss';

const ContactPage = ({ location }) => {
  const breadcrumbs = [{ url: `/${ROUTE_NAMES.CONTACT}`, name: 'Contacto' }];
  const siteData = useSiteQuery();
  const { address, phone_numbers, email, social, horario } = siteData.site.siteMetadata;
  const siteMeta = [
    {
      key: 'Email',
      value: email,
    },
    {
      key: 'Instagram',
      value: social.instagram.text,
    },
    {
      key: 'Teléfono',
      value: phone_numbers.landline.text,
    },
    {
      key: 'Whatsapp',
      value: social.whatsapp.text,
    },
    {
      key: 'Dirección',
      value: address.text,
      isFullLine: true,
    },
    {
      key: 'Horarios de atención',
      value: [horario.line1, horario.line2],
      isFullLine: true,
    },
  ];

  return (
    <>
      <Seo title="Contacto" description="Envianos tu consulta y te responderemos a la brevedad" />
      <section className={style.main}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Contactanos</h1>
        <div className={style.container}>
          <div className={style.form}>
            <ContactForm location={location} />
          </div>
          <div className={style.meta}>
            <Specs specs={siteMeta} />
            <Map className={style.map} />
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactPage;
