import cx from 'classnames';
import React from 'react';

import { useSiteQuery } from '@/query/site';

import * as style from './map.module.scss';

const Map = ({ className }) => {
  const siteData = useSiteQuery();
  const { googleApiKey, gmapsPlaceId } = siteData.site.siteMetadata;
  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=place_id:${gmapsPlaceId}`;

  return (
    <div className={cx(style.main, className)}>
      <iframe
        title="Google Maps"
        src={mapUrl}
        width="100%"
        height="400"
        frameBorder="0"
        aria-hidden="false"
      ></iframe>
    </div>
  );
};

export default Map;
