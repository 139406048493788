import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import CloseButton from '@/components/common/CloseButton';
import { ROUTE_NAMES, stringToSlug } from '@/utils/url';

import * as style from './card.module.scss';

const CardRow = ({ product, onRemoveProduct }) => {
  const { title, shortDescription, uuid } = product;
  const productUrl = `/${ROUTE_NAMES.PRODUCTS}/${stringToSlug(title)}`;

  const removeProduct = (event, uuid) => {
    event.preventDefault();
    onRemoveProduct(uuid);
  };

  return (
    <article className={cx(style.main, style.productRow)} data-uuid={uuid}>
      <Link to={productUrl} className={style.link}>
        <section className={style.content}>
          <h1 className={style.title}>{title}</h1>
          <p className={style.description}>{shortDescription}</p>
        </section>
        <CloseButton
          className={style.closeButton}
          onClick={(event) => removeProduct(event, uuid)}
        />
      </Link>
    </article>
  );
};

export default CardRow;
