import React, { useEffect, useRef, useState } from 'react';

import Button from '@/components/common/Button';
import { Email, Phone, Text, Textarea } from '@/components/common/Input';
import { useProductsQuery } from '@/query/products';

import ReferencedProducts from '../ReferencedProducts';
import * as style from './form.module.scss';

const ContactForm = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const referencedProductsSearchParam = searchParams.get('referencedProducts');

  const [isLoading, setIsLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [formResult, setFormResult] = useState('active');
  const [referencedProductsQuery, setReferencedProductsQuery] = useState(
    referencedProductsSearchParam ? [referencedProductsSearchParam] : [],
  );
  const productsQuery = useProductsQuery();
  const allProducts = productsQuery.allGoogleSpreadsheetProducts.edges.map((e) => e.node);
  const referencedProducts = allProducts.filter((e) =>
    referencedProductsQuery.includes(e.uuid.toString()),
  );
  const handleUpdateProducts = (products) => {
    setScrollPosition(window.scrollY);
    setReferencedProductsQuery(products.map((e) => e.uuid));
  };
  const referencedProductsRef = useRef();
  const form = useRef();

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [referencedProducts, scrollPosition]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = JSON.stringify({
      name: form.current.name.value,
      email: form.current.email.value,
      phone: form.current.phone.value,
      message: form.current.message.value,
      referencedProducts,
    });

    fetch('/api/sendmail', {
      method: 'post',
      body: formData,
    })
      .then((res) => {
        const result = res.ok ? 'success' : 'error';
        setFormResult(result);
      })
      .catch((err) => {
        setFormResult('error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {formResult === 'success' ? (
        <div className={style.result}>
          <h2>Gracias por tu mensaje!</h2>
          <p className={style.lead}>En breve nos pondremos en contacto para responderte.</p>
        </div>
      ) : formResult === 'error' ? (
        <div className={style.result}>
          <h2>Ups! Puede fallar...</h2>
          <p className={style.lead}>
            Tu consulta no pudo ser enviada.
            <br />
            Por favor volvé a intentar en unos minutos.
          </p>
          <Button
            onClick={() => {
              setFormResult('active');
            }}
          >
            Volver a intentar
          </Button>
        </div>
      ) : (
        <form className={style.main} onSubmit={handleSubmit} ref={form}>
          <p className={style.lead}>Envianos tu consulta y te responderemos a la brevedad</p>
          <div className={style.formField}>
            <label htmlFor="name">Nombre</label>
            <Text required name="name" placeholder="Carlos Alberto" />
          </div>
          <div className={style.formField}>
            <label htmlFor="email">Email</label>
            <Email required name="email" placeholder="nombre@email.com" />
          </div>
          <div className={style.formField}>
            <label htmlFor="phone">Teléfono</label>
            <Phone name="phone" placeholder="+54 294 421 2755" />
          </div>
          <div ref={referencedProductsRef} className={style.formField}>
            <ReferencedProducts
              allProducts={allProducts}
              referencedProducts={referencedProducts}
              onUpdateProducts={handleUpdateProducts}
            />
          </div>
          <div className={style.formField}>
            <label htmlFor="message">Mensaje</label>
            <Textarea required name="message" rows="6" placeholder="Envianos tu consulta" />
          </div>
          <Button className={style.button} type="submit" isSecondary isBlock isLoading={isLoading}>
            Enviar
          </Button>
        </form>
      )}
    </>
  );
};

export default ContactForm;
