import React, { useRef, useState } from 'react';

import { Text } from '@/components/common/Input';
import Popover from '@/components/common/Popover';
import ProductCardRow from '@/components/section/Product/CardRow';
import { accessibleOnClick } from '@/utils/react';
import { normalizeString } from '@/utils/string';

import * as style from './referencedproducts.module.scss';

const ReferencedProducts = ({ allProducts, referencedProducts, onUpdateProducts }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const handleRemoveProduct = (uuid) => {
    onUpdateProducts(referencedProducts.filter((e) => e.uuid !== uuid));
  };
  const handleFilter = () => {
    const searchTerms = normalizeString(searchField.current.value)
      .split(' ')
      .filter((e) => e !== '');
    const referencedProductsIds = referencedProducts.map((e) => e.uuid);
    const nonSelectedProducts = allProducts.filter((e) => !referencedProductsIds.includes(e.uuid));
    const products = nonSelectedProducts.filter((product) => {
      const title = normalizeString(product.title);
      const shortDescription = normalizeString(product.shortDescription);
      const comparer = `${title} ${shortDescription}`;
      return searchTerms.every((term) => comparer.includes(term));
    });
    setFilteredProducts(products);
  };
  const searchField = useRef();
  const handleProductSelection = (product) => {
    onUpdateProducts([...referencedProducts, product]);
    setFilteredProducts(filteredProducts.filter((e) => e.uuid !== product.uuid));
  };

  return (
    <>
      <label htmlFor="phone">Consultar acerca de</label>
      <Popover
        className={style.productSearch}
        renderChildren={() => (
          <ul className={style.results}>
            {filteredProducts.length
              ? filteredProducts.map((product) => (
                  <li
                    className={style.resultListItem}
                    {...accessibleOnClick(() => handleProductSelection(product))}
                    key={product.uuid}
                  >
                    <span className={style.title}>{product.title}</span>
                    <span className={style.description}>{product.shortDescription}</span>
                  </li>
                ))
              : `No se encontraron resultados para "${(searchField.current || {}).value}"`}
          </ul>
        )}
      >
        <Text
          autoComplete="off"
          name="search"
          placeholder="Buscar productos"
          type="text"
          onChange={() => handleFilter()}
          ref={searchField}
        />
      </Popover>
      {referencedProducts.length ? (
        <div className={style.referencedProducts}>
          {referencedProducts.map((product) => (
            <ProductCardRow
              key={product.uuid}
              product={product}
              onRemoveProduct={(uuid) => handleRemoveProduct(uuid)}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default ReferencedProducts;
